var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import SaveForNextTimePage from "../general/SaveForNextTimePage.vue";
import gql from "graphql-tag";
import createStudentFromPurchase from "../../../api/mutations/createStudentFromPurchase";
export default Vue.extend({
    components: {
        SaveForNextTimePage: SaveForNextTimePage,
    },
    apollo: {
        purchase: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query PurchaseSaveForNextTimeQuery($id: ID!) {\n          purchase(id: $id) {\n            id\n          }\n        }\n      "], ["\n        query PurchaseSaveForNextTimeQuery($id: ID!) {\n          purchase(id: $id) {\n            id\n          }\n        }\n      "]))),
            variables: function () {
                return {
                    id: this.$route.params.id,
                };
            },
        },
    },
    methods: {
        process: function (password) {
            return this.$apollo
                .mutate(createStudentFromPurchase({
                purchase: this.$route.params.id,
                password: password,
            }))
                .then(function () { return undefined; });
        },
    },
});
var templateObject_1;
